body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.input-group .form-control+.input-group-btn>.btn, .input-group .form-control-alt+.input-group-btn>.btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  color: #333;
  color: var(--color-neutral-1);
  border: 1px solid #616267 !important;
  border: 1px solid var(--color-neutral-2);
  font-size: 16px;
  font-size: var(--typography-base);
  box-shadow: none;
  display: flex;
  width: 100%;
  height: 48px;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
}
.form-control, .form-control-alt{
  color: #333;
  color: var(--color-neutral-1);
  background-color: #fff;
  background-color: var(--color-neutral-white);
  font-family: 'FS Elliot Web Regular',Arial,arial,sans-serif;
  font-family: var(--typography-font-default);
  border: 1px solid #616267 !important;
  border: 1px solid var(--color-neutral-2);
  font-size: 16px;
  font-size: var(--typography-base);
  box-shadow: none;
  display: flex;
  width: 100%;
  height: 48px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  appearance: auto;
}